import styled from 'styled-components'

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 95%;
  bottom: 20px;
  height: 50px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  color: black;
`
