import React, { Component } from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import ScrollButton from './components/scrollButton'
import './custom.css'
import SignIn from './components/signIn.component'
import AuthService from './services/auth.service'
import Details from './components/attendance.component'
import { withTranslation } from 'react-i18next'
import bigLogo from './img/logo/bigLogo.png'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AttendanceReport from './components/attendanceReport'
import AttendanceCompensation from './components/attendanceCompensation'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: AuthService.currentUser()
    }
  }

  reloadUser = () => {
    this.setState({ user: AuthService.currentUser() })
  }

  signOut = async () => {
    await AuthService.signOut()
    window.location.reload()
    localStorage.clear()
  }

  render = () => {
    const { t } = this.props

    return (
      <div className='bodyLogin'>
        {localStorage.getItem('username') == null ? (
          <SignIn onChange={this.reloadUser} />
        ) : (
          <div>
            <nav className='navbar navbar-expand navbar-dark bg-dark text-white opacity9 sticky-top'>
              <Link to={'/attendance'} className='navbar-brand'>
                <img src={bigLogo} className='bigLogoSmall ' alt='logo' />
              </Link>
              <div className='navbar-nav ml-auto'>
                <li className='nav-item m-2'>
                  <div className='d-flex justify-content-center  mt-2'>
                    <AccountCircleIcon />
                    {localStorage.getItem('username') != null
                      ? localStorage.getItem('username')
                      : ''}
                  </div>
                </li>
                <li className='nav-item m-2'>
                  <Link
                    to={'/'}
                    onClick={this.signOut}
                    className='nav-link'
                    to='/'
                  >
                    <span title={t('menu.logout')} className='ml-2'>
                      <ExitToAppIcon className='secondaryColor whiteShadow' />
                    </span>
                  </Link>
                </li>
              </div>
            </nav>
            <ScrollButton />
            <div className='m-5'>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={() => {
                    return this.state.user ? (
                      <Redirect to='attendance' />
                    ) : (
                      <Redirect to='/' />
                    )
                  }}
                />

                <Route exact path={'/attendance'} component={Details} />
                <Route
                  exact
                  path={'/attendance/report'}
                  component={AttendanceReport}
                />
                <Route
                  exact
                  path={'/attendance/compensation'}
                  component={AttendanceCompensation}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(App)
