import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

let config = {
  apiKey: "AIzaSyAKDVoxVyeeG3n-Pd14l6MO1dn5cR8cpbA",
  authDomain: "sym-pro-timetracker.firebaseapp.com",
  databaseURL: "https://sym-pro-timetracker-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sym-pro-timetracker",
  storageBucket: "sym-pro-timetracker.appspot.com",
  messagingSenderId: "506165011384",
  appId: "1:506165011384:web:8a1a20932563da30c7254d",
  measurementId: "G-FN4SPWNB5X"
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(config)
}

export const database = firebase.database()
export const auth = firebase.auth()
