import { database } from '../firebase'
import {
  USERS_SECTION,
  USERS_FIELD_USERNAME,
  USERS_FIELD_EMAIL,
  USERS_FIELD_IS_MANAGER
} from '../constants'

const db = database.ref('/' + USERS_SECTION)

class UserService {
  getAll() {
    return db
  }

  getEmail = async (username) => {
    let user = await db
      .orderByChild(USERS_FIELD_USERNAME)
      .equalTo(username)
      .once('value')
    user = await user.val()
    if (user) {
      const info = Object.values(user)[0]
      if (info[USERS_FIELD_IS_MANAGER]) return info[USERS_FIELD_EMAIL]
      else return 'modules.signIn.only_managers_can_login'
    }
    return null
  }
}

export default new UserService()
