import React, { Component } from 'react'

import ButtonFilters from './buttonFilters'
import UserService from '../services/user.service'
import LeaveService from '../services/leaves.service'
import MUIDataTable from 'mui-datatables'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Divider } from '@material-ui/core'
import ReactExport from 'react-export-excel'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ExportFiles from '../services/exportFiles'
import { RangeDatePicker } from 'react-google-flight-datepicker'
import 'react-google-flight-datepicker/dist/main.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class AttendanceCompensation extends Component {
  constructor(props) {
    super(props)
    var rangeListInitial = []
    var firstDay = moment().startOf('month')
    while (moment(firstDay) <= moment(new Date())) {
      rangeListInitial.push(moment(firstDay).format('DD/MM/YYYY'))
      firstDay = moment(firstDay).add(1, 'days').format('DD/MM/YYYY')
    }
    this.state = {
      users: [],
      leaves: [],
      holidays: [],
      timetables: [],
      attendance: [],
      filterList: [],
      excelHolidays: [],
      holidaysDayRange: [],
      startFilterDate: moment().startOf('month'),
      endFilterDate: new Date()
    }
  }
  componentDidMount = () => {
    UserService.getAll().on('value', this.onUsersChange)
    LeaveService.getAll().on('value', this.onLeaveChange)
  }
  componentWillUnmount = () => {
    UserService.getAll().off('value', this.onUsersChange)
    LeaveService.getAll().off('value', this.onLeaveChange)
  }

  onUsersChange = (items) => {
    let users = []

    items.forEach((item) => {
      const key = item.key
      const data = item.val()

      users.push({
        key: key,
        name: data.Name,
        email: data.Email,
        leaves: {
          holidays: data.leaves.hl,
          lastYearLeaves: data.leaves.past_holidays ?? 0
        },
        isAdmin: data.isAdmin != null ? data.isAdmin : false
      })
    })

    this.setState({ users })
  }

  filterData(filterList) {
    const { holidays } = this.state
    var user = filterList[0][0]
    var filteredHolidays = holidays.filter(
      (holiday) => holiday.person.toLowerCase() == user.toLowerCase()
    )
    this.setState({ excelHolidays: filteredHolidays })
  }

  onLeaveChange = (items) => {
    const { users, holidaysDayRange } = this.state
    const { t } = this.props
    let holidayList = {}
    let holidays = []
    let leaveList = {}
    let leaves = []
    let holidayRange = JSON.parse(JSON.stringify(holidaysDayRange))
    let takenHolidays = []
    let totalHolidays = []
    let todayDate = moment().format('YYYY-MM-DD')
    items.forEach((item) => {
      const key = item.key
      const user = users.find((u) => u.key === key)

      let data = item.val()
      data = Object.keys(data).map((key) => [key, data[key]])
      if (!user.isAdmin) {
        data.forEach((x) => {
          const info = x[1]
          var paidLeaves = 0,
            nonpaidLeaves = 0,
            sickLeaves = 0,
            hl = 0,
            lastYearHolidays = 0

          if (info.type === 'pl' && info.status === 'approved') {
            paidLeaves += 1
          }
          if (info.type === 'npl' && info.status === 'approved') {
            nonpaidLeaves += 1
          }
          if (info.type === 'sl' && info.status === 'approved') {
            sickLeaves += 1
          }
          if (info.type === 'hl' && info.status === 'approved') {
            var toDt = moment(info.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            var today = moment(todayDate, 'YYYY-MM-DD')

            if (moment(info.appliedDate).year !== moment().year) {
              var from = moment(info.fromDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )

              while (moment(from) <= moment(toDt)) {
                totalHolidays.push(moment(from).format('YYYY-MM-DD'))
                from = moment(from).add(1, 'days').format('YYYY-MM-DD')
              }
              lastYearHolidays = totalHolidays.length
            } else {
              var from = moment(info.fromDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )

              totalHolidays = []
              while (moment(from) <= moment(toDt)) {
                totalHolidays.push(moment(from).format('YYYY-MM-DD'))
                from = moment(from).add(1, 'days').format('YYYY-MM-DD')
              }
              hl = totalHolidays.length
            }
            var personHolidayDays = []
            var bool = false
            var dayCount = 0

            if (moment(toDt).isBefore(today)) {
              var from = moment(info.fromDate, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
              while (moment(from) <= moment(toDt)) {
                personHolidayDays.push(moment(from).format('YYYY-MM-DD'))
                from = moment(from).add(1, 'days').format('YYYY-MM-DD')
              }
              if (holidayRange.length != 0) {
                bool = holidayRange.some(function (filterDay) {
                  return personHolidayDays.includes(filterDay)
                })
                dayCount = personHolidayDays.length
              }
            }
            if (bool == true) {
              takenHolidays.push({
                from: info.fromDate,
                to: info.toDate,
                person: user.name,
                count: dayCount
              })
            }
          }
          if (!holidayList[user.key]) {
            holidayList[user.key] = {
              anual_holidays: user.leaves.holidays,
              person: user.name,
              past_holidays: user.leaves.lastYearLeaves,
              requested_holidays: 0,
              pending_holidays: 0,
              pl: 0,
              npl: 0,
              sl: 0
            }
          }

          holidayList[user.key] = {
            anual_holidays: user.leaves.holidays,
            person: user.name,
            past_holidays: holidayList[user.key].past_holidays,
            requested_holidays: holidayList[user.key].requested_holidays + hl,
            pending_holidays:
              holidayList[user.key].anual_holidays +
              holidayList[user.key].past_holidays -
              (holidayList[user.key].requested_holidays + hl),
            pl: holidayList[user.key].pl + paidLeaves,
            npl: holidayList[user.key].npl + nonpaidLeaves,
            sl: holidayList[user.key].sl + sickLeaves
          }

          if (!leaveList[user.key]) {
            leaveList[user.key] = {
              person: user.name,
              pl: 0,
              npl: 0,
              sl: 0
            }
          }
          leaveList[user.key] = {
            person: user.name,
            pl: leaveList[user.key].pl + paidLeaves,
            npl: leaveList[user.key].npl + nonpaidLeaves,
            sl: leaveList[user.key].sl + sickLeaves
          }
        })
      }
    })

    Object.keys(holidayList).forEach((key) =>
      holidays.push({
        person: holidayList[key].person,
        anual_holidays: holidayList[key].anual_holidays,
        past_holidays: holidayList[key].past_holidays,
        requested_holidays: holidayList[key].requested_holidays,
        pending_holidays: holidayList[key].pending_holidays,
        pl: holidayList[key].pl,
        sl: holidayList[key].sl,
        npl: holidayList[key].npl
      })
    )

    Object.keys(leaveList).forEach((key) =>
      leaves.push({
        person: leaveList[key].person,
        pl: leaveList[key].pl,
        npl: leaveList[key].npl,
        sl: leaveList[key].sl
      })
    )
    var filteredHolidays = JSON.parse(JSON.stringify(leaves))

    this.setState({
      holidays,
      leaves,
      excelHolidays: filteredHolidays,
      holidaysDayRange: takenHolidays
    })
  }

  generatePDF = () => {
    const { t } = this.props
    const { excelHolidays } = this.state
    var headerArray = []
    var bodyArray = []

    headerArray = [
      t('modules.attendance.employee'),
      t('leaves.annual_holidays'),
      t('leaves.past_holidays'),
      t('leaves.request_holidays'),
      t('leaves.pending_holidays'),
      t('leaves.pl'),
      t('leaves.npl'),
      t('leaves.sl')
    ]
    excelHolidays.map((leave) =>
      bodyArray.push([
        leave.person,
        leave.anual_holidays,
        leave.past_holidays,
        leave.requested_holidays,
        leave.pending_holidays,
        leave.pl,
        leave.sl,
        leave.npl
      ])
    )

    ExportFiles.generatePDF(
      headerArray,
      bodyArray,
      t('leaves.leaves'),
      t('common.page')
    )
  }
  onDayChange(startDate, endDate) {
    var rangeList = []
    var ss = startDate
    while (moment(ss) <= moment(endDate)) {
      rangeList.push(moment(ss).format('YYYY-MM-DD'))
      ss = moment(ss).add(1, 'days').format('YYYY-MM-DD')
    }
    this.setState({
      startFilterDate: startDate,
      endFilterDate: endDate,
      holidaysDayRange: rangeList
    })
    LeaveService.getAll().on('value', this.onLeaveChange)
  }
  render() {
    const { t } = this.props
    const { leaves, holidays, excelHolidays, holidaysDayRange } = this.state
    const holidayTable = [
      {
        label: t('modules.attendance.employee'),
        name: 'person',
        options: {
          sort: true,
          filter: true
        }
      },
      {
        label: t('leaves.annual_holidays'),
        name: 'anual_holidays',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.past_holidays'),
        name: 'past_holidays',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.request_holidays'),
        name: 'requested_holidays',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.pending_holidays'),
        name: 'pending_holidays',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.pl'),
        name: 'pl',
        options: {
          sort: true,
          filter: false,
          display: false,
          viewColumns: false
        }
      },
      {
        label: t('leaves.npl'),
        name: 'npl',
        options: {
          sort: true,
          filter: false,
          display: false,
          viewColumns: false
        }
      },
      {
        label: t('leaves.sl'),
        name: 'sl',
        options: {
          sort: true,
          filter: false,
          display: false,
          viewColumns: false
        }
      }
    ]
    const leavesTable = [
      {
        label: t('modules.attendance.employee'),
        name: 'person',
        options: {
          sort: true,
          filter: false,
          filterType: 'multiselects'
        }
      },
      {
        label: t('leaves.pl'),
        name: 'pl',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.npl'),
        name: 'npl',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.sl'),
        name: 'sl',
        options: {
          sort: true,
          filter: false
        }
      }
    ]

    const rangeTable = [
      {
        label: t('modules.attendance.employee'),
        name: 'person',
        options: {
          sort: true,
          filter: true,
          filterType: 'multiselects'
        }
      },
      {
        label: t('leaves.from'),
        name: 'from',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.to'),
        name: 'to',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('leaves.days_count'),
        name: 'count',
        options: {
          sort: true,
          filter: false
        }
      }
    ]

    const options = {
      download: false,
      print: false,
      jumpToPage: true,
      rowsPerPage: 20,
      selectableRows: 'none',
      textLabels: {
        filter: {
          all: t('filters.all'),
          title: t('filters.filters'),
          reset: t('filters.reset')
        },
        pagination: {
          next: t('filters.next'),
          previous: t('filters.previous'),
          rowsPerPage: t('filters.rowsPerPage'),
          displayRows: t('filters.displayRows'),
          jumpToPage: t('filters.jumpToPage')
        },
        toolbar: {
          search: t('filters.search'),
          viewColumns: t('filters.viewColumns'),
          filterTable: t('filters.filterTable')
        },
        body: {
          noMatch: t('filters.noMatch'),
          toolTip: t('filters.sort_of'),
          columnHeaderTooltip: (column) => t('filters.sort_of') + column.label
        },
        viewColumns: {
          title: t('filters.viewColumns')
        }
      },
      onFilterChange: (columnChanged, filterList) => this.filterData(filterList)
    }

    return (
      <div>
        <span title={t('common.exportPDF')}>
          <button
            onClick={this.generatePDF}
            className='btn buttonPrimaryColor float-right m-3'
          >
            <PictureAsPdfIcon />
          </button>
        </span>
        <ExcelFile
          element={
            <buttton className='btn btn-primary buttonPrimaryColor float-right m-3'>
              {t('common.download_excel')}{' '}
            </buttton>
          }
          filename={t('leaves.holidays')}
        >
          <ExcelSheet data={excelHolidays} name={t('leaves.holidays')}>
            <ExcelColumn
              label={t('modules.attendance.employee')}
              value='person'
            />
            <ExcelColumn
              label={t('leaves.annual_holidays')}
              value='anual_holidays'
            />
            <ExcelColumn
              label={t('leaves.past_holidays')}
              value='past_holidays'
            />
            <ExcelColumn
              label={t('leaves.request_holidays')}
              value='requested_holidays'
            />
            <ExcelColumn
              label={t('leaves.pending_holidays')}
              value='pending_holidays'
            />
            <ExcelColumn label={t('leaves.pl')} value='pl' />
            <ExcelColumn label={t('leaves.npl')} value='npl' />
            <ExcelColumn label={t('leaves.sl')} value='sl' />
          </ExcelSheet>
        </ExcelFile>
        <ButtonFilters></ButtonFilters>
        {holidays.length === 0 ? (
          <div className='spinner'>
            <h3 className='center primaryColor'>{t('common.loading')}</h3>
          </div>
        ) : (
          <div>
            <MUIDataTable
              title={t('leaves.holidays')}
              columns={holidayTable}
              data={holidays}
              options={options}
            />
            <Divider variant='middle' style={{ marginTop: '5%' }} />
            <RangeDatePicker
              startDate={this.state.startFilterDate}
              endDate={this.state.endFilterDate}
              onChange={(startDate, endDate) =>
                this.onDayChange(startDate, endDate)
              }
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date(2100, 0, 1)}
              dateFormat='DD/MM/YYYY'
              monthFormat='MMM YYYY'
              startDatePlaceholder={t('report.start_date')}
              endDatePlaceholder={t('report.end_date')}
              disabled={false}
              className='react-google-flight-datepicker' //
              startWeekDay='monday'
            />
            <MUIDataTable
              title={t('leaves.holiday_range')}
              columns={rangeTable}
              data={holidaysDayRange}
              options={options}
            />
            <Divider variant='middle' style={{ marginTop: '5%' }} />
            <MUIDataTable
              title={t('leaves.other_leaves')}
              columns={leavesTable}
              data={leaves}
              options={options}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(AttendanceCompensation)
