import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class ButtonFilters extends Component {
  render() {
    const { t } = this.props
    return (
      <div>
        <NavLink
          to={'/attendance'}
          exact={true}
          activeClassName='activeLink'
          className='btn btn-dark m-3'
        >
          {' '}
          {t('filter_buttons.details')}
        </NavLink>
        <NavLink
          to={'/attendance/report'}
          exact={true}
          activeClassName='activeLink'
          className='btn btn-dark m-3'
        >
          {t('filter_buttons.report')}
        </NavLink>
        <NavLink
          to={'/attendance/compensation'}
          exact={true}
          activeClassName='activeLink'
          className='btn btn-dark m-3'
        >
          {t('filter_buttons.leaves_report')}
        </NavLink>
      </div>
    )
  }
}

export default withTranslation()(ButtonFilters)
