import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'

const fallbackLng = ['es']
const availableLanguages = ['es', 'en']

i18n
  .use(Backend)
  //.use(LanguageDetector) // detect user language
  .use(initReactI18next)

  .init({
    fallbackLng,
    backend: {
      loadPath: `.{{process.env.REACT_APP_BASE_URL}}/locales/{{lng}}/translation.json`
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
