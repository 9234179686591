import React, { Component } from 'react'
import UserService from '../services/user.service'
import ShiftTypesService from '../services/shiftTypes.service'
import AttendanceService from '../services/attendance.service'
import TimetablesService from '../services/timetables.service'
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'
import { withTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import swal from 'sweetalert'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ThumbUp from '@material-ui/icons/ThumbUp'
import ThumbDown from '@material-ui/icons/ThumbDown'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import SaveIcon from '@material-ui/icons/Save'
import Modal from './modal'
import 'react-google-flight-datepicker/dist/main.css'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { auth } from '../firebase'

import {
  FormGroup,
  FormLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import ButtonFilters from './buttonFilters'
import ReactExport from 'react-export-excel'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ExportFiles from '../services/exportFiles'
import AttendanceHelper from '../helpers/AttendanceHelper'

import {
  FORMAT_DATE,
  DATE_START_TO_CALCULATE,
  FORMAT_TIME_DIFF,
  FORMAT_BIG_TIME_DIFF
} from '../constants'
import DatePickerRange from './datePickerRange'


const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
class Attendance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      attendance: [],
      shifts: [],
      showModal: false,
      newAttendance: {
        employee: null,
        date: null,
        shift: null,
        checkIn: null,
        checkOut: null
      },
      currentUser: null,
      excelAtt: [],
      filteredAttendance: [],
      rangeList: [],
      totalHourResult: 0,
      startFilterDate: null,
      endFilterDate: null,
      filteredByDate: [],
      filtteredUser: null
    }
  }

  componentDidMount = () => {
    UserService.getAll().on('value', this.onUsersChange)
    ShiftTypesService.getAll().on('value', this.onShiftChange)
    this.timetableCall().then(() => {
      AttendanceService.getAll().on('value', this.onAttendanceChange)
    })
  }

  componentWillUnmount = () => {
    UserService.getAll().off('value', this.onUsersChange)
    AttendanceService.getAll().off('value', this.onAttendanceChange)
  }

  componentDidUpdate = () => {
    const users = this.state.users
    if (users == null) {
      UserService.getAll().off('value', this.onUsersChange)
    }
  }

  timetableCall = () => {
    return new Promise((resolve) => {
      resolve(TimetablesService.getAll().on('value', this.onTimetablesChange))
    })
  }

  onShiftChange = (items) => {
    let shifts = []

    items.forEach((item) => {
      const key = item.key
      const data = item.val()
      shifts.push({
        key: key,
        label: data
      })
    })

    this.setState({ shifts })
  }

  onUsersChange = (items) => {
    let users = []
    let currentUser = auth.currentUser
    items.forEach((item) => {
      const key = item.key
      const data = item.val()
      users.push({
        key: key,
        name: data.Name,
        email: data.Email,
        isAdmin: data.isAdmin != null ? data.isAdmin : false
      })
    })

    this.setState({ users , currentUser})
  }

  onTimetablesChange = (items) => {
    const { users } = this.state
    let timetables = []

    items.forEach((item) => {
      const key = item.key
      let data = item.val()

      const user = users.find((u) => u.key === key)
      data = Object.keys(data).map((key) => [key, data[key]])
      if (!user?.isAdmin) {
        // eslint-disable-next-line
        data.map((x) => {
          const info = x[1]

          timetables.push({
            key: x[0],
            startDate: info.startDate,
            endDate: info.endDate,
            startTime: info.startTime,
            endTime: info.endTime,
            priority: info.priority,
            shiftTypeId: info.shiftTypeId,
            userId: key
          })
        })
      }
    })

    this.setState({ timetables })

    this.attHelper = new AttendanceHelper({
      t: this.props.t,
      timetables: timetables
    })
  }

  onAttendanceChange = (items) => {
    const { users, shifts, startFilterDate, endFilterDate, filtteredUser } =
      this.state

    let attendance = []
    let totalhours = 0

    items.forEach((item) => {
      const key = item.key
      const user = users.find((u) => u.key === key)
      let data = item.val()
      data = Object.keys(data).map((key) => [key, data[key]]).sort((a, b) => a[1].datetimeIn.localeCompare(b[1].datetimeIn)).reverse()
      if (!user?.isAdmin) {
        data.forEach((x) => {
          const info = x[1]
          attendance.push(
            this.attHelper.calculateTimes(x[0], info, shifts, user)
          )
        })
      }
    })
    var filteredAttendanceEdited = ''
    filteredAttendanceEdited = JSON.parse(JSON.stringify(attendance))
    filteredAttendanceEdited.push({
      superResult: totalhours
    })
    this.setState(
      {
        attendance,
        filteredAttendance: filteredAttendanceEdited,
        totalHourResult: totalhours
      },
      () => {
        if (endFilterDate || startFilterDate || filtteredUser) {
          return this.filterByDate(startFilterDate, endFilterDate)
        }
      }
    )
  }

  handleChange = (event) => {
    const { modifiedItem} = this.state
    this.setState({
      modifiedItem: {
        ...modifiedItem,
        [event.target.name]: event.target.value
      }
    })
  }

  editableField = (fieldName, row, time) => {
    const { editingRow, modifiedItem } = this.state
    const type = time ? 'time' : 'date';
    var valueField = "";
    var show = true;
    var inOut = fieldName == 'dateTimeInEdit' || fieldName == 'timeIn' ? 'In' :
    (fieldName == 'dateTimeOutEdit' || fieldName == 'timeOut' ? 'Out' : "");
    if(modifiedItem){
    show = (inOut == "In" && modifiedItem.inAuth == 'rejected') ||
                (inOut == "Out" && modifiedItem.outAuth == 'rejected') ? false : true;
    }
    if(time && row.user + row.key === editingRow){
      valueField = moment(modifiedItem[fieldName], 'H:mm').format('HH:mm') ;
    }else{
      if(row.user + row.key === editingRow){
        valueField = moment(modifiedItem[fieldName], 'DD/MM/yyyy').format('yyyy-MM-DD');
        valueField = valueField != 'Invalid date' ? valueField : moment(modifiedItem[fieldName], 'yyyy-MM-DD').format('yyyy-MM-DD')
      }else{
        show = (inOut == "In" && row.inAuth == 'rejected') ||
                (inOut == "Out" && row.outAuth == 'rejected') ? false : true;
        valueField = time ? row[fieldName] : moment(row.date, 'DD/MM/yyyy').format('yyyy-MM-DD')
      }
    }

    if (row.user + row.key === editingRow && show){
      return (
        <div>
          <input
            type={type}
            name={fieldName}
            value={valueField}
            onChange={this.handleChange}
          />
        </div>
      )
    }else{
      return time ? row[fieldName] : ""
    }
}
  edit = (row) => {
    const { user, key } = row
    const editingRow = user + key
    this.setState({ editingRow, modifiedItem: row })
  }

  update = () => {
    const {currentUser} = this.state
    const { user, key, timeIn, timeOut, dateTimeInEdit, dateTimeOutEdit, dateIn, dateOut } = this.state.modifiedItem
    var formattedDateIn = dateTimeInEdit ?  moment(dateTimeInEdit, 'yyyy-MM-DD').format('yyyy-MM-DD') : moment(dateIn).format('yyyy-MM-DD')
    formattedDateIn = moment(formattedDateIn).year() > 100 ? formattedDateIn : moment(dateTimeInEdit, 'DD/MM/yyyy').format('yyyy-MM-DD');
    var formattedDateOut = dateTimeOutEdit ?  moment(dateTimeOutEdit, 'yyyy-MM-DD').format('yyyy-MM-DD') : moment(dateOut).format('yyyy-MM-DD')
    formattedDateOut = moment(formattedDateOut).year() > 100 ? formattedDateOut : moment(dateTimeOutEdit, 'DD/MM/yyyy').format('yyyy-MM-DD');
    var formattedTimeIn = timeIn != ' - ' ? moment(timeIn, "HH:mm").format("HH:mm") : moment(this.state.modifiedItem['shiftTimeIn'], "HH:mm").format("HH:mm");
    var formattedTimeOut = timeOut != ' - ' ? moment(timeOut, "HH:mm").format("HH:mm") : moment(this.state.modifiedItem['shiftTimeOut'], "HH:mm").format("HH:mm");
    const { filteredAttendance, totalHourResult } = this.state
    AttendanceService.update(user, key, {
      datetimeIn: formattedDateIn + ' ' + formattedTimeIn + ':00.000',
      datetimeOut: formattedDateOut + ' ' + formattedTimeOut + ':00.000',
      inAuth: this.state.modifiedItem.inAuth == 'rejected' ? 'rejected' :  (timeIn && timeIn != ' - ' ? 'accepted' :  ''),
      outAuth: this.state.modifiedItem.outAuth == 'rejected' ? 'rejected' :  (timeOut && timeOut != ' - ' ? 'accepted' :  ''),
      editedAt: moment().format('yyyy-MM-DD HH:mm:ss'),
      editedBy: currentUser.uid
    })

    this.setState({
      editingRow: null,
      modifiedItem: null
    })
  }
  notUpdate = () => {
    this.setState({
      editingRow: null,
      modifiedItem: null
    })
  }
  delete = (row) => {
    const { t } = this.props
    const { user, key } = row

    swal({
      title: t('common.delete'),
      text: t('common.youSure?'),
      buttons: [t('common.no'), t('common.yes')],
      icon: 'warning'
    }).then((response) => {
      if (response) {
        AttendanceService.delete(user, key)
        swal({
          icon: 'success',
          text: t('common.deletedSuccess'),
          buttons: false,
          timer: '2000'
        })
      }
    })
  }

  actions = (row) => {
    const { t } = this.props
    const { editingRow } = this.state
    const { user, key } = row
    if (user + key === editingRow)
      return (
        <div className='actions'>
          <IconButton>
            <span
              title={t('common.save')}
              onClick={this.update}
              className='primaryColor'
            >
              <SaveIcon />
            </span>
          </IconButton>
          <IconButton>
            <span
              title={t('common.cancel')}
              onClick={this.notUpdate}
              className='primaryColor'
            >
              <CancelIcon />
            </span>
          </IconButton>
        </div>
      )

    return (
      <div className='actions'>
        <span
          title={t('common.edit')}
          onClick={() => this.edit(row)}
          className='mr-2'
        >
          <IconButton>
            <EditIcon className='text-warning' />
          </IconButton>
        </span>
        <span
          title={t('common.delete')}
          onClick={() => this.delete(row)}
          className='ml-2'
        >
          <IconButton>
            <DeleteForeverIcon className='text-danger' />
          </IconButton>
        </span>
      </div>
    )
  }

  approveAttendance = (attendance) => {
    const { t } = this.props
    let text = t('modules.attendance.accept')
    attendance.isIn
      ? (text += t('modules.attendance.checkIn'))
      : (text += t('modules.attendance.checkOut'))

    swal({
      title: text,
      text: t('common.youSure?'),
      buttons: [t('common.no'), t('common.yes')],
      icon: 'warning'
    }).then((response) => {
      if (response) {
        if (attendance.isIn) {
          AttendanceService.update(attendance.row.user, attendance.row.key, {
            inAuth: 'accepted'
          })
          text = t('modules.attendance.checkIn')
        } else {
          AttendanceService.update(attendance.row.user, attendance.row.key, {
            outAuth: 'accepted'
          })
          text = t('modules.attendance.checkOut')
        }
        text += t('modules.attendance.acceptedSuccessfully')
        swal({
          icon: 'success',
          text: text,
          buttons: false,
          timer: '2000'
        })
      }
    })
  }

  cancelAttendance = (attendance) => {
    const { t } = this.props
    let text = t('modules.attendance.reject')
    attendance.isIn
      ? (text += t('modules.attendance.checkIn'))
      : (text += t('modules.attendance.checkOut'))

    swal({
      title: text,
      text: t('common.youSure?'),
      buttons: [t('common.no'), t('common.yes')],
      icon: 'warning'
    }).then((response) => {
      if (response) {
        if (attendance.isIn) {
          AttendanceService.update(attendance.row.user, attendance.row.key, {
            inAuth: 'rejected',
            teoricIn:
              attendance.row.date + ' ' + attendance.row.shiftTimeIn + ':00.000'
          })
          text = t('modules.attendance.checkIn')
        } else {
          AttendanceService.update(attendance.row.user, attendance.row.key, {
            outAuth: 'rejected',
            teoricOut:
              attendance.row.date +
              ' ' +
              attendance.row.shiftTimeOut +
              ':00.000'
          })
          text = t('modules.attendance.checkOut')
        }
        text += text = t('modules.attendance.rejectedSuccessfully')
        swal({
          icon: 'success',
          text: text,
          buttons: false,
          timer: '2000'
        })
      }
    })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  addAttendance = () => {
    const { t} = this.props
    const { employee, shift, checkIn, checkOut, date } =
      this.state.newAttendance
    const isAnyNullField =
      employee === null ||
      shift === null ||
      checkIn === null ||
      checkOut === null ||
      date === null
    const isAnyEmptyField =
      employee === '' ||
      shift === '' ||
      checkIn === '' ||
      checkOut === '' ||
      date === ''

    if (isAnyNullField || isAnyEmptyField) {
      swal({
        icon: 'error',
        text: t('common.AnyCantBeEmpty'),
        buttons: false,
        timer: '2000'
      })
    } else {
      AttendanceService.save(employee, {
        datetimeIn: date + ' ' + checkIn + ':00.000',
        datetimeOut: date + ' ' + checkOut + ':00.000',
        inAuth: '',
        inRequiresAuth: 'false',
        lateInMsg: '',
        lateOutMsg: '',
        outAuth: '',
        outRequiresAuth: 'false',
        shiftEventId: shift,
        createdAt: moment().format('yyyy-MM-DD HH:mm:ss'),
        createdBy: auth.currentUser.uid
      })
      this.setState({ showModal: false })
      swal({
        icon: 'success',
        text: t('common.addedSuccess'),
        buttons: false,
        timer: '2000'
      })
    }
  }

  handleNewValues = (input) => {
    const { newAttendance } = this.state
    this.setState({
      newAttendance: {
        ...newAttendance,
        [input.target.name]: input.target.value
      }
    })
  }

  modalForm = () => {
    const { t } = this.props
    const { users, shifts } = this.state
    users.sort((a, b) => a.name.localeCompare(b.name))
    shifts.sort((a, b) => a.label.localeCompare(b.label))
    return (
      <Modal
        title={t('modules.attendance.addNewAttendance')}
        visible={this.state.showModal}
        buttonSave={true}
        onCloseModal={this.closeModal}
        onSaveModal={this.addAttendance}
      >
        <center>
          <div className='form-group m-3 col-md-6'>
            <label className='primaryColor'>
              {t('modules.attendance.employee')}
            </label>
            <Select
              className='form-control'
              onChange={this.handleNewValues}
              name='employee'
              required
              defaultValue={""}
            >
              {users.map((user) => (
                <MenuItem value={user.key}>{user.name}</MenuItem>
              ))}
            </Select>
          </div>

          <div className='form-group m-3 col-md-6'>
            <label className='primaryColor'>
              {t('modules.attendance.date')}
            </label>
            <input
              type='date'
              name='date'
              className='form-control'
              onChange={this.handleNewValues}
              required
            />
          </div>

          <div className='form-group m-3 col-md-6'>
            <label className='primaryColor'>
              {t('modules.attendance.shift')}
            </label>
            <Select
              className='form-control'
              onChange={this.handleNewValues}
              name='shift'
              required
              defaultValue={""}
            >
              {shifts.map((shift) => (
                <MenuItem value={shift.key}>
                  {t('modules.attendance.shifts.' + shift.label)}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className='form-group m-3 col-md-6'>
            <label className='primaryColor'>
              {t('modules.attendance.checkIn')}
            </label>
            <input
              type='time'
              name='checkIn'
              className='form-control'
              onChange={this.handleNewValues}
              required
            />
          </div>

          <div className='form-group m-3 col-md-6'>
            <label className='primaryColor'>
              {t('modules.attendance.checkOut')}
            </label>
            <input
              type='time'
              name='checkOut'
              className='form-control'
              onChange={this.handleNewValues}
              required
            />
          </div>
        </center>
      </Modal>
    )
  }

  filterData(filterList) {
    const { startFilterDate, endFilterDate } = this.state
    this.setState({ filtteredUser: filterList[2][0] }, () => {
      this.filterByDate(startFilterDate, endFilterDate)
    })
  }

  generatePDF = () => {
    const { t } = this.props
    const { filteredAttendance, totalHourResult } = this.state

    let bodyArray = []
    var filterList = JSON.parse(JSON.stringify(filteredAttendance))
    if (filterList[filterList.length - 1].hasOwnProperty('superResult')) {
      filterList.pop()
    }

    filterList.forEach((att) =>
      bodyArray.push([
        att.person,
        att.date,
        t('modules.attendance.shifts.' + att.shift),
        att.timeIn,
        att.lateInMsg,
        att.timeOut,
        att.lateOutMsg,
        att.shiftTimeIn,
        att.shiftTimeOut,
        att.inResult,
        att.outResult,
        att.totalResult
      ])
    )
    bodyArray.push([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      totalHourResult
    ])

    let headerArray = [
      t('modules.attendance.employee'),
      t('modules.attendance.date'),
      t('modules.attendance.shift'),
      t('modules.attendance.timeIn'),
      t('attendance_reasons.lateIn'),
      t('modules.attendance.timeOut'),
      t('attendance_reasons.lateOut'),
      t('modules.attendance.shiftTimeIn'),
      t('modules.attendance.shiftTimeOut'),
      t('modules.attendance.extraInHours'),
      t('modules.attendance.extraOutHours'),
      t('modules.attendance.totalExtraHours'),
      t('modules.attendance.total')
    ]

    ExportFiles.generatePDF(
      headerArray,
      bodyArray,
      t('titles.attendanceHistory'),
      t('common.page')
    )
  }

  filterByDate = (startDate, endDate) => {
    const { attendance, filtteredUser } = this.state
    var start = moment(startDate).format(FORMAT_DATE)
    var end = moment(endDate).format(FORMAT_DATE)
    var filteerAtt = JSON.parse(JSON.stringify(attendance))
    var filtt = ''
    var superResult = null
    //Valid dates and no user
    if (start != 'Invalid date' && end != 'Invalid date' && !filtteredUser) {
      var filteredAtte = filteerAtt.filter((att) => {
        var date = att.date
        return (
          moment(date, FORMAT_DATE).isBetween(
            moment(start, FORMAT_DATE),
            moment(end, FORMAT_DATE),
            '[]'
          ) || moment(date, FORMAT_DATE).isSame(moment(start, FORMAT_DATE))
        )
      })
      superResult = this.calculateResults(filteredAtte, superResult)
      filtt = JSON.parse(JSON.stringify(filteredAtte))
      filtt.push({ superResult: superResult })
      this.setState({
        filteredByDate: filteredAtte,
        startFilterDate: startDate,
        endFilterDate: endDate,
        filteredAttendance: filtt,
        totalHourResult: superResult
      })
      //No user and no dates
    } else if (
      start == 'Invalid date' &&
      end == 'Invalid date' &&
      !filtteredUser
    ) {
      superResult = this.calculateResults(attendance, superResult)
      filtt = JSON.parse(JSON.stringify(attendance))
      filtt.push({ superResult: superResult })
      this.setState({
        filteredByDate: attendance,
        totalHourResult: superResult,
        filteredAttendance: filtt
      })
      //Just user
    } else if (
      start == 'Invalid date' &&
      end == 'Invalid date' &&
      filtteredUser
    ) {
      var filteredAtte = filteerAtt.filter((holiday) => {
        return holiday.person.toLowerCase() == filtteredUser.toLowerCase()
      })
      superResult = this.calculateResults(filteredAtte, superResult)
      filtt = JSON.parse(JSON.stringify(filteredAtte))
      filtt.push({ superResult: superResult })
      this.setState({
        filteredByDate: filteredAtte,
        startFilterDate: null,
        endFilterDate: null,
        filteredAttendance: filtt,
        totalHourResult: superResult
      })
      //Dates and user
    } else if (
      start != 'Invalid date' &&
      end != 'Invalid date' &&
      filtteredUser
    ) {
      var filteredAtte = filteerAtt.filter((holiday) => {
        return holiday.person.toLowerCase() == filtteredUser.toLowerCase()
      })
      filteredAtte = filteredAtte.filter((att) => {
        var date = att.date
        return (
          moment(date, FORMAT_DATE).isBetween(
            moment(start, FORMAT_DATE),
            moment(end, FORMAT_DATE),
            '[]'
          ) || moment(date, FORMAT_DATE).isSame(moment(start, FORMAT_DATE))
        )
      })

      superResult = this.calculateResults(filteredAtte, superResult)
      filtt = JSON.parse(JSON.stringify(filteredAtte))
      filtt.push({ superResult: superResult })
      this.setState({
        filteredByDate: filteredAtte,
        startFilterDate: startDate,
        endFilterDate: endDate,
        filteredAttendance: filtt,
        totalHourResult: superResult
      })
    }
  }

  calculateResults = (attendance, superResult) => {
    let totalMinutes = 0
    attendance.forEach((item) => {
      totalMinutes += item.totalOvertimeMinutes
    })
    const sign = totalMinutes > 0 ? '' : '-'
    const visualDiffFormat =
      totalMinutes > 60 * 24 ? FORMAT_BIG_TIME_DIFF : FORMAT_TIME_DIFF

    return (
      sign +
      moment(DATE_START_TO_CALCULATE)
        .startOf('day')
        .add(Math.abs(totalMinutes), 'minutes')
        .format(visualDiffFormat)
    )
  }

  timeDiffClass = (timeDiff) => {
    if (timeDiff.includes('+')) {
      return 'text-success'
    } else if (timeDiff.includes('-')) {
      return 'text-danger'
    } else {
      return 'text-info'
    }
  }

  render() {
    const { t } = this.props
    const {
      attendance,
      filteredAttendance,
      startFilterDate,
      endFilterDate,
      filteredByDate
    } = this.state

    const columns = [
      {
        name: 'key',
        label: t('modules.attendance.key'),
        options: {
          sort: false,
          filter: false,
          visible: false,
          display: false,
          viewColumns: false
        }
      },
      {
        name: 'pending',
        label: t('modules.attendance.pendingApprove'),
        options: {
          sort: false,
          filter: true,
          visible: false,
          display: false,
          filterType: 'checkbox',
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            if (value) {
              if (row.pending === undefined) {
                return '?'
              } else {
                return row.pending
              }
            }
          }
        }
      },
      {
        name: 'person',
        label: t('modules.attendance.employee'),
        options: {
          sort: true,
          filter: true,
          filterType: 'multiselects'
        }
      },
      {
        name: 'date',
        label: t('modules.attendance.date'),
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'shift',
        label: t('modules.attendance.shift'),
        options: {
          sort: true,
          filter: true,
          filterType: 'checkbox',
          customBodyRender: (value, tableMeta, updateValue) => {
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])

            if (row.shift === undefined) {
              return '?'
            } else {
              return t('modules.attendance.shifts.' + row.shift, row)
            }
          }
        }
      },
      {

        name: 'timeIn',
        label: t('modules.attendance.timeIn'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let isAceptedRejected = ''
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            let obj = { row: row, isIn: true }

            let iconButtons = <div></div>
            if (row.inRequiresAuth === 'true') {
              iconButtons = (
                <div>
                  <IconButton
                    className='text-success'
                    aria-label='add an alarm'
                    onClick={() => {
                      this.approveAttendance(obj)
                    }}
                  >
                    <span title={t('modules.attendance.accept')}>
                      <CheckCircleIcon fontSize='small' />
                    </span>
                  </IconButton>
                  <IconButton
                    className='text-danger'
                    aria-label='add an alarm'
                    onClick={() => {
                      this.cancelAttendance(obj)
                    }}
                  >
                    <span title={t('modules.attendance.reject')}>
                      <CancelIcon fontSize='small' />
                    </span>
                  </IconButton>
                </div>
              )
              if (row.inAuth === 'accepted') {
                isAceptedRejected = 'accepted'
                iconButtons = (
                  <ThumbUp
                    className='text-success ml-1 mb-1'
                    fontSize='small'
                  />
                )
              }
              if (row.inAuth === 'rejected') {
                isAceptedRejected = 'rejected'
                iconButtons = (
                  <ThumbDown className='text-danger ml-1' fontSize='small' />
                )
              }
            }

            return (
              <div
                className={
                  isAceptedRejected === 'accepted'
                    ? 'text-success'
                    : isAceptedRejected === 'rejected'
                    ? 'text-danger'
                    : ''
                }
              >
                <span>
                  {row.inRequiresAuth === 'true' && row.inAuth === '' ? (
                    <PriorityHighIcon className='text-warning' />
                  ) : (
                    ''
                  )}
                </span>
                {this.editableField('timeIn', row, true)}
                <br />
                {this.editableField('dateTimeInEdit', row, false)}
                <br />
                {iconButtons}
              </div>
            )
          },
          filterType: 'custom',
          customFilterListRender: (v) => {
            if (v[0] && v[1]) {
              return (
                t('modules.attendance.startTime') +
                `: ${v[0]}, ` +
                t('modules.attendance.endTime') +
                ` : ${v[1]}`
              )
            } else if (v[0]) {
              return t('modules.attendance.startTime') + `: ${v[0]}`
            } else if (v[1]) {
              return t('modules.attendance.endTime') + `: ${v[1]}`
            }
            return false
          },
          filterOptions: {
            names: [],
            logic(time, filters) {
              let currentTimeIn = moment()
              let check = moment(
                currentTimeIn.format('yyyy-MM-DD') + ' ' + time
              )
              let from = moment(
                currentTimeIn.format('yyyy-MM-DD') + ' ' + filters[0]
              )
              let to = moment(
                currentTimeIn.format('yyyy-MM-DD') + ' ' + filters[1]
              )

              if (filters[0] && filters[1] && check >= to && check <= from) {
                return true
              } else if (filters[0] && check >= to) {
                return true
              } else if (filters[1] && check <= from) {
                return true
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t('modules.attendance.timeIn')}</FormLabel>
                <FormGroup row>
                  <TextField
                    id='startTime'
                    label={t('modules.attendance.startTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id='endTime'
                    label={t('modules.attendance.endTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            )
          }
        }
      },
      {
        name: 'lateInMsg',
        label: t('attendance_reasons.lateIn'),
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'timeOut',
        label: t('modules.attendance.timeOut'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let isAceptedRejected = ''
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            let obj = { row: row, isIn: false }
            let iconButtons = <div></div>
            if (row.outRequiresAuth === 'true') {
              iconButtons = (
                <div>
                  <IconButton
                    className='text-success'
                    aria-label='add an alarm'
                    onClick={() => {
                      this.approveAttendance(obj)
                    }}
                  >
                    <CheckCircleIcon fontSize='small' />
                  </IconButton>
                  <IconButton
                    className='text-danger'
                    aria-label='add an alarm'
                    onClick={() => {
                      this.cancelAttendance(obj)
                    }}
                  >
                    <CancelIcon fontSize='small' />
                  </IconButton>
                </div>
              )
              if (row.outAuth && row.outAuth === 'accepted') {
                isAceptedRejected = 'accepted'
                iconButtons = (
                  <ThumbUp
                    className='text-success ml-1 mb-1'
                    fontSize='small'
                  />
                )
              }
              if (row.outAuth && row.outAuth === 'rejected') {
                isAceptedRejected = 'rejected'
                iconButtons = (
                  <ThumbDown className='text-danger ml-1' fontSize='small' />
                )
              }
            }

            return (
              <div
                className={
                  isAceptedRejected === 'accepted'
                    ? 'text-success'
                    : isAceptedRejected === 'rejected'
                    ? 'text-danger'
                    : ''
                }
              >
                <span>
                  {row.outRequiresAuth === 'true' && row.outAuth === '' ? (
                    <PriorityHighIcon className='text-warning' />
                  ) : (
                    ''
                  )}
                </span>
                {this.editableField('timeOut', row, true)}
                <br />
                {this.editableField('dateTimeOutEdit', row, false)}
                <br />
                {iconButtons}
              </div>
            )
          },
          filterType: 'custom',
          customFilterListRender: (v) => {
            if (v[0] && v[1]) {
              return (
                t('modules.attendance.startTime') +
                `: ${v[0]}, ` +
                t('modules.attendance.endTime') +
                ` : ${v[1]}`
              )
            } else if (v[0]) {
              return t('modules.attendance.startTime') + `: ${v[0]}`
            } else if (v[1]) {
              return t('modules.attendance.endTime') + `: ${v[1]}`
            }

            return false
          },
          filterOptions: {
            names: [],
            logic(time, filters) {
              let currentTimeOut = moment()
              let check = moment(
                currentTimeOut.format('yyyy-MM-DD') + ' ' + time
              )
              let from = moment(
                currentTimeOut.format('yyyy-MM-DD') + ' ' + filters[0]
              )
              let to = moment(
                currentTimeOut.format('yyyy-MM-DD') + ' ' + filters[1]
              )

              if (filters[0] && filters[1] && check >= to && check <= from) {
                return true
              } else if (filters[0] && check >= to) {
                return true
              } else if (filters[1] && check <= from) {
                return true
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t('modules.attendance.timeOut')}</FormLabel>
                <FormGroup row>
                  <TextField
                    id='startTime'
                    label={t('modules.attendance.startTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />

                  <TextField
                    id='endTime'
                    label={t('modules.attendance.endTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            )
          }
        }
      },
      {
        name: 'lateOutMsg',
        label: t('attendance_reasons.lateOut'),
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'shiftTimeIn',
        label: t('modules.attendance.shiftTimeIn'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: (_value, tableMeta, _updateValue) => {
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            return (
              <span className={row.inAuth === 'rejected' ? 'text-success' : ''}>
                {row.shiftTimeIn}
              </span>
            )
          },
          filterType: 'custom',
          customFilterListRender: (v) => {
            if (v[0] && v[1]) {
              return (
                t('modules.attendance.startTime') +
                `: ${v[0]}, ` +
                t('modules.attendance.endTime') +
                ` : ${v[1]}`
              )
            } else if (v[0]) {
              return t('modules.attendance.startTime') + `: ${v[0]}`
            } else if (v[1]) {
              return t('modules.attendance.endTime') + `: ${v[1]}`
            }
            return false
          },
          filterOptions: {
            names: [],
            logic(time, filters) {
              if (filters.length > 1) {
                let currentShiftTimeIn = moment()
                let check = moment(
                  currentShiftTimeIn.format('yyyy-MM-DD') + ' ' + time
                )
                let from = moment(
                  currentShiftTimeIn.format('yyyy-MM-DD') + ' ' + filters[0]
                )
                let to = moment(
                  currentShiftTimeIn.format('yyyy-MM-DD') + ' ' + filters[1]
                )
                if (filters[0] && filters[1] && check >= to && check <= from) {
                  return true
                } else if (filters[0] && check >= to) {
                  return true
                } else if (filters[1] && check <= from) {
                  return true
                } else if (time === t('modules.attendance.free')) {
                  return true
                } else if (
                  time === t('modules.attendance.hl') ||
                  time === t('modules.attendance.sl') ||
                  time === t('modules.attendance.pl') ||
                  time === t('modules.attendance.npl')
                ) {
                  return true
                }
              }

              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t('modules.attendance.shiftTimeIn')}</FormLabel>
                <FormGroup row>
                  <TextField
                    id='startTime'
                    label={t('modules.attendance.startTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id='startTime'
                    label={t('modules.attendance.startTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id='endTime'
                    label={t('modules.attendance.endTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            )
          }
        }
      },
      {
        name: 'shiftTimeOut',
        label: t('modules.attendance.shiftTimeOut'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: (_value, tableMeta, _updateValue) => {
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            return (
              <span
                className={row.outAuth === 'rejected' ? 'text-success' : ''}
              >
                {row.shiftTimeOut}
              </span>
            )
          },
          filterType: 'custom',
          customFilterListRender: (v) => {
            if (v[0] && v[1]) {
              return (
                t('modules.attendance.startTime') +
                `: ${v[0]}, ` +
                t('modules.attendance.endTime') +
                ` : ${v[1]}`
              )
            } else if (v[0]) {
              return t('modules.attendance.startTime') + `: ${v[0]}`
            } else if (v[1]) {
              return t('modules.attendance.endTime') + `: ${v[1]}`
            }

            return false
          },
          filterOptions: {
            names: [],
            logic(time, filters) {
              if (filters.length > 1) {
                if (time !== t('modules.attendance.free')) {
                  return false
                } else if (
                  time !== t('modules.attendance.hl') ||
                  time !== t('modules.attendance.sl') ||
                  time !== t('modules.attendance.pl') ||
                  time !== t('modules.attendance.npl')
                ) {
                  return false
                } else {
                  let currentShiftTimeOut = moment()
                  let check = moment(
                    currentShiftTimeOut.format('yyyy-MM-DD') + ' ' + time
                  )
                  let from = moment(
                    currentShiftTimeOut.format('yyyy-MM-DD') + ' ' + filters[0]
                  )
                  let to = moment(
                    currentShiftTimeOut.format('yyyy-MM-DD') + ' ' + filters[1]
                  )
                  if (
                    filters[0] &&
                    filters[1] &&
                    check >= to &&
                    check <= from
                  ) {
                    return true
                  } else if (filters[0] && check >= to) {
                    return true
                  } else if (filters[1] && check <= from) {
                    return true
                  }
                }
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>{t('modules.attendance.shiftTimeOut')}</FormLabel>
                <FormGroup row>
                  <TextField
                    id='startTime'
                    label={t('modules.attendance.startTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id='endTime'
                    label={t('modules.attendance.endTime')}
                    type='time'
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            )
          }
        }
      },
      {
        name: 'overtime',
        label: t('modules.attendance.overtime'),
        options: {
          setCellProps: () => ({
            align: 'center'
          }),
          sort: true,
          filter: false,
          customBodyRender: (_value, tableMeta, _updateValue) => {
            const row =
              startFilterDate != null && endFilterDate != null
                ? filteredByDate.find((a) => a.key === tableMeta.rowData[0])
                : attendance.find((a) => a.key === tableMeta.rowData[0])
            return (
              <div
                className={
                  row.datetimeOut === '' ? 'MuiTablePagination-toolbar' : ''
                }
              >
                <b>{t('modules.attendance.timeIn')}: </b>
                <span className={this.timeDiffClass(row.inResult)}>
                  {row.inResult}
                </span>
                <br />
                <b>{t('modules.attendance.timeOut')}: </b>
                <span className={this.timeDiffClass(row.outResult)}>
                  {row.outResult}
                </span>
                <hr />
                <b>{t('modules.attendance.total')}: </b>
                <span className={this.timeDiffClass(row.totalResult)}>
                  {row.totalResult}
                </span>
              </div>
            )
          }
        }
      },
      {
        name: t('common.actions'),
        options: {
          empty: true,
          sort: false,
          filter: false,
          customBodyRender: (_value, tableMeta, _updateValue) => {
            const row = attendance.find((a) => a.key === tableMeta.rowData[0])
            return this.actions(row)
          }
        }
      }
    ]

    const options = {
      download: false,
      print: false,
      jumpToPage: true,
      rowsPerPage: 100,
      selectableRows: 'none',
      setCellHeaderProps: (value) => ({
        style: {
          // 'line-height': '100%'
        }
      }),
      textLabels: {
        filter: {
          all: t('filters.all'),
          title: t('filters.filters'),
          reset: t('filters.reset')
        },
        pagination: {
          next: t('filters.next'),
          previous: t('filters.previous'),
          rowsPerPage: t('filters.rowsPerPage'),
          displayRows: t('filters.displayRows'),
          jumpToPage: t('filters.jumpToPage')
        },
        toolbar: {
          search: t('filters.search'),
          viewColumns: t('filters.viewColumns'),
          filterTable: t('filters.filterTable')
        },
        body: {
          noMatch: t('filters.noMatch'),
          toolTip: t('filters.sort_of'),
          columnHeaderTooltip: (column) => t('filters.sort_of') + column.label
        },
        viewColumns: {
          title: t('filters.viewColumns')
        }
      },
      onFilterChange: (columnChanged, filterList) => this.filterData(filterList)
    }

    return (
      <div>
        <span title={t('modules.attendance.addNewAttendance')}>
          <button
            onClick={this.openModal}
            className='btn buttonPrimaryColor m-3 float-right'
          >
            <AddCircleOutlineIcon />
            {t('common.add')}
          </button>
        </span>
        <span title={t('common.exportPDF')}>
          <button
            onClick={this.generatePDF}
            className='btn buttonPrimaryColor m-3 float-right'
          >
            <PictureAsPdfIcon />
          </button>
        </span>
        <ExcelFile
          element={
            <buttton className='btn btn-primary buttonPrimaryColor float-right m-3'>
              {t('common.download_excel')}{' '}
            </buttton>
          }
          filename={t('titles.attendanceHistory')}
        >
          <ExcelSheet
            data={filteredAttendance}
            name={t('titles.attendanceHistory')}
          >
            <ExcelColumn
              label={t('modules.attendance.employee')}
              value='person'
            />
            <ExcelColumn label={t('modules.attendance.date')} value='date' />
            <ExcelColumn
              label={t('modules.attendance.shift')}
              value='shiftToExcel'
            />
            <ExcelColumn
              label={t('modules.attendance.timeIn')}
              value='timeIn'
            />
            <ExcelColumn
              label={t('attendance_reasons.lateIn')}
              value='lateInMsg'
            />
            <ExcelColumn
              label={t('modules.attendance.timeOut')}
              value='timeOut'
            />
            <ExcelColumn
              label={t('attendance_reasons.lateOut')}
              value='lateOutMsg'
            />
            <ExcelColumn
              label={t('modules.attendance.shiftTimeIn')}
              value='shiftTimeIn'
            />
            <ExcelColumn
              label={t('modules.attendance.shiftTimeOut')}
              value='shiftTimeOut'
            />
            <ExcelColumn
              label={t('modules.attendance.extraInHours')}
              value='inResult'
            />
            <ExcelColumn
              label={t('modules.attendance.extraOutHours')}
              value='outResult'
            />
            <ExcelColumn
              label={t('modules.attendance.totalExtraHours')}
              value='totalResult'
            />
            <ExcelColumn
              label={t('modules.attendance.total')}
              value='superResult'
            />
          </ExcelSheet>
        </ExcelFile>
        <ButtonFilters></ButtonFilters>

        <center>
          <div>
            {this.modalForm()}

            {attendance.length === 0 ? (
              <div className='spinner'>
                <h3 className='center primaryColor '>{t('common.loading')}</h3>
              </div>
            ) : (
              <div>
                <DatePickerRange
                  filter={(startDate, endDate) =>
                    this.filterByDate(startDate, endDate)
                  }
                  t={t}
                />

                <MUIDataTable
                  ref={this.refTable}
                  title={<div>{t('titles.attendanceHistory')}</div>}
                  columns={columns}
                  data={
                    startFilterDate != null && endFilterDate != null
                      ? filteredByDate
                      : attendance
                  }
                  options={options}
                />
              </div>
            )}
          </div>
        </center>
      </div>
    )
  }
}

export default withTranslation()(Attendance)
