import React, { Component } from 'react'

import ButtonFilters from './buttonFilters'
import UserService from '../services/user.service'

import AttendanceService from '../services/attendance.service'
import TimetablesService from '../services/timetable.service'
import { RangeDatePicker } from 'react-google-flight-datepicker'
import 'react-google-flight-datepicker/dist/main.css'
import { withTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ExportFiles from '../services/exportFiles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class AttendanceReport extends Component {
  constructor(props) {
    super(props)
    UserService.getAll().on('value', this.onUsersChange)
    var firstDay = moment().startOf('month')
    var rangeListInitial = []
    while (moment(firstDay) <= moment(new Date())) {
      rangeListInitial.push(moment(firstDay).format('YYYY-MM-DD'))
      firstDay = moment(firstDay).add(1, 'days').format('YYYY-MM-DD')
    }
    this.state = {
      users: [],
      attendance: [],
      timetables: [],
      startDate: moment().startOf('month'),
      endDate: new Date(),
      rangeList: rangeListInitial,
      filteredReport: []
    }
  }

  componentDidMount = async () => {
    UserService.getAll().on('value', this.onUsersChange)
    this.attendanceCall().then((ee) => {
      TimetablesService.getAll().on('value', this.onTimetableChange)
    })
  }

  componentWillUnmount = () => {
    UserService.getAll().off('value', this.onUsersChange)
    AttendanceService.getAll().off('value', this.onAttendanceChange)
  }

  componentDidUpdate = () => {
    const users = this.state.users
    if (users == null) {
      UserService.getAll().off('value', this.onUsersChange)
    }
  }

  attendanceCall = (items) => {
    return new Promise((resolve) => {
      resolve(AttendanceService.getAll().on('value', this.onAttendanceChange))
    })
  }

  onTimetableChange = (items) => {
    const { users, rangeList, attendance } = this.state
    let timetables = []
    let timetableList = []

    items.forEach((item) => {
      let realHour = 0
      let difference = 0
      let hourCount = 0
      const key = item.key

      const user = users.find((u) => u.key === key)

      let attendanceList = attendance.filter((att) => att.person === user.name)
      attendanceList.forEach((att) => {
        realHour = att.real_hour
      })

      let data = item.val()
      data = Object.keys(data).map((key) => [key, data[key]])
      if (!user.isAdmin) {
        rangeList.forEach((day) => {
          //timetables no ciclicos
          let timetable = data.filter((tmt) =>
            moment(tmt[1].startDate).isSame(moment(day))
          )
          if (timetable.length >= 3) {
            //LUNCH
            let lunchShift = timetable.filter((t) => t[1].shiftTypeId == 0)

            //DINNER
            let dinnerShift = timetable.filter((t) => t[1].shiftTypeId == 1)
            timetable = []
            if (lunchShift.length != 0) {
              timetable.push(lunchShift)
            } else if (dinnerShift.length != 0) {
              timetable.push(dinnerShift)
            }
          }

          timetable.forEach((t) => {
            if (t[1] != null || t[1] != undefined) {
              switch (t[1].startTime) {
                case 'Free':
                case 'hl':
                case 'pl':
                case 'npl':
                case 'sl':
                  hourCount += 0
                  break
                default:
                  if (
                    moment(day).isSame(moment(t[1].startTime)) &&
                    t[1].startTime != undefined
                  ) {
                    hourCount += moment(
                      t[1].startDate + ' ' + t[1].endTime
                    ).diff(
                      moment(t[1].startDate + ' ' + t[1].startTime),
                      'hours'
                    )
                  } else if (t[1][1] != undefined) {
                    hourCount += moment(
                      t[1][1].startDate + ' ' + t[1][1].endTime
                    ).diff(
                      moment(t[1][1].startDate + ' ' + t[1][1].startTime),
                      'hours'
                    )
                  }
                  break
              }
            }
          })
          let current = data.filter((tmt) => tmt[1].isCurrent === 1)

          current = current.sort((a, b) =>
            moment(b[1].startDate).isBefore(moment(a[1].startDate)) ? 1 : -1
          )
          let start = current[0][1].startDate

          if (moment(day).isAfter(moment(start))) {
            let cyc = data.filter((tmt) => {
              return (
                moment(tmt[1].startDate).weekday() === moment(day).weekday() &&
                tmt[1].priority === 99 &&
                !moment(tmt[1].startDate).isSame(moment(day))
              )
            })
            cyc = cyc.sort((a, b) =>
              moment(a[1].startDate).isBefore(moment(b[1].startDate)) ? 1 : -1
            )
            if (cyc.length > 2) {
              let var1 = cyc[0]
              let var2 = cyc[1]
              cyc = []
              cyc.push(var1)
              cyc.push(var2)
            }
            cyc.forEach((t) => {
              switch (t[1].startTime) {
                case 'Free':
                case 'hl':
                case 'pl':
                case 'npl':
                case 'sl':
                  hourCount += 0
                  break
                default:
                  hourCount += moment(t[1].startDate + ' ' + t[1].endTime).diff(
                    moment(t[1].startDate + ' ' + t[1].startTime),
                    'hours'
                  )
                  break
              }
            })
          } else if (moment(day).isBefore(moment(start))) {
            hourCount += 0
          }

          if (timetable.length == 1) {
            current = current.sort((a, b) =>
              moment(b[1].startDate).isBefore(moment(a[1].startDate)) ? 1 : -1
            )
            let start = current[0][1].startDate
            if (timetable[0].shiftTypeId == 0) {
              if (moment(day).isAfter(moment(start))) {
                let cyc = data.filter((tmt) => {
                  return (
                    tmt[1].shiftTypeId == 1 &&
                    moment(tmt[1].startDate).weekday() ===
                      moment(day).weekday() &&
                    tmt[1].priority === 99 &&
                    tmt[1].isCurrent == 1 &&
                    !moment(tmt[1].startDate).isSame(moment(day))
                  )
                })
                cyc = cyc.sort((a, b) =>
                  moment(a[1].startDate).isBefore(moment(b[1].startDate))
                    ? 1
                    : -1
                )

                cyc.forEach((t) => {
                  if (t[1] != null || t[1] != undefined) {
                    switch (t[1].startTime) {
                      case 'Free':
                      case 'hl':
                      case 'pl':
                      case 'npl':
                      case 'sl':
                        hourCount += 0
                        break
                      default:
                        hourCount += moment(
                          t[1].startDate + ' ' + t[1].endTime
                        ).diff(
                          moment(t[1].startDate + ' ' + t[1].startTime),
                          'hours'
                        )
                        break
                    }
                  }
                })
              }
            }
          }

          difference = realHour - hourCount
        })
        if (!timetableList[user.key]) {
          timetableList[user.key] = {
            person: user.name,
            teoric_hour: isNaN(hourCount) ? 0 : hourCount,
            real_hour: isNaN(realHour) ? 0 : realHour,
            difference: isNaN(difference) ? 0 : difference
          }
        }
      }
    })

    Object.keys(timetableList).forEach((key) =>
      timetables.push({
        real_hour: timetableList[key].real_hour,
        teoric_hour: timetableList[key].teoric_hour,
        person: timetableList[key].person,
        difference: timetableList[key].difference,
        filters: {
          startDate: this.state.startDate,
          endDate: this.state.endDate
        }
      })
    )

    var filtRepo = JSON.parse(JSON.stringify(timetables))
    this.setState({ timetables, filteredReport: filtRepo })
  }
  onUsersChange = (items) => {
    let users = []

    items.forEach((item) => {
      const key = item.key
      const data = item.val()
      users.push({
        key: key,
        name: data.Name,
        email: data.Email,
        isAdmin: data.isAdmin != null ? data.isAdmin : false
      })
    })

    this.setState({ users })
  }

  onAttendanceChange = (items) => {
    const { users, rangeList } = this.state

    let attendanceList = {}
    let attendance = []

    items.forEach((item) => {
      const key = item.key
      const user = users.find((u) => u.key === key)
      let data = item.val()

      data = Object.keys(data).map((key) => [key, data[key]])
      if (!user.isAdmin) {
        // eslint-disable-next-line
        data.map((x) => {
          let realHours = 0
          const info = x[1]
          var timeIn = moment(info.datetimeIn)
          var timeOut = moment(info.datetimeOut)
          rangeList.forEach((days) => {
            if (moment(days).isSame(moment(timeIn).format('YYYY-MM-DD'))) {
              if (timeIn != 'Free' && timeOut != 'Free')
                realHours = timeOut.diff(timeIn, 'hours')
            }
          })

          let realHour = 0
          if (!attendanceList[user.key]) {
            attendanceList[user.key] = {
              teoric_hour: 0,
              real_hour: 0,
              difference: 0,
              person: user.name
            }
          }
          realHour = attendanceList[user.key].real_hour + realHours
          attendanceList[user.key] = {
            real_hour: realHour,
            person: user.name
          }
        })
      }
    })

    Object.keys(attendanceList).forEach((key) =>
      attendance.push({
        real_hour:
          attendanceList[key].real_hour !== isNaN
            ? attendanceList[key].real_hour
            : 0,
        person: attendanceList[key].person
      })
    )
    this.setState({ attendance })
  }

  onDayChange(startDate, endDate) {
    var rangeList = []
    var ss = startDate
    while (moment(ss) <= moment(endDate)) {
      rangeList.push(moment(ss).format('YYYY-MM-DD'))
      ss = moment(ss).add(1, 'days').format('YYYY-MM-DD')
    }
    this.setState({
      startDate: startDate,
      endDate: endDate,
      rangeList: rangeList
    })
    this.attendanceCall().then((ee) => {
      TimetablesService.getAll().on('value', this.onTimetableChange)
    })
  }
  filterData(filterList) {
    const { timetables } = this.state
    var user = filterList[0][0]
    var filteredRepo = timetables.filter(
      (tmt) => tmt.person.toLowerCase() == user.toLowerCase()
    )
    this.setState({ filteredReport: filteredRepo })
  }
  generatePDF = () => {
    const { t } = this.props
    const { filteredReport } = this.state

    var bodyArray = []
    filteredReport.map((tim) =>
      bodyArray.push([
        tim.person,
        tim.real_hour,
        tim.teoric_hour,
        tim.difference
      ])
    )

    var headerArray = [
      t('modules.attendance.employee'),
      t('report.theoric_hour'),
      t('report.real_hour'),
      t('report.difference')
    ]

    ExportFiles.generatePDF(
      headerArray,
      bodyArray,
      t('report.report'),
      t('common.page')
    )
  }
  datePicker = () => {
    const { t } = this.props
    return (
      <div>
        <RangeDatePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={(startDate, endDate) =>
            this.onDayChange(startDate, endDate)
          }
          minDate={new Date(1900, 0, 1)}
          maxDate={new Date(2100, 0, 1)}
          dateFormat='DD/MM/YYYY'
          monthFormat='MMM YYYY'
          startDatePlaceholder={t('report.start_date')}
          endDatePlaceholder={t('report.end_date')}
          disabled={false}
          className='react-google-flight-datepicker' //
          startWeekDay='monday'
        />
      </div>
    )
  }
  render() {
    const { t } = this.props
    const { timetables, filteredReport } = this.state

    const columns = [
      {
        label: t('modules.attendance.employee'),
        name: 'person',
        options: {
          sort: true,
          filter: true
        }
      },
      {
        label: t('report.theoric_hour'),
        name: 'teoric_hour',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('report.real_hour'),
        name: 'real_hour',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        label: t('report.difference'),
        name: 'difference',
        options: {
          sort: true,
          filter: false
        }
      }
    ]

    const options = {
      download: false,
      print: false,
      jumpToPage: true,
      rowsPerPage: 20,
      selectableRows: 'none',
      textLabels: {
        filter: {
          all: t('filters.all'),
          title: t('filters.filters'),
          reset: t('filters.reset')
        },
        pagination: {
          next: t('filters.next'),
          previous: t('filters.previous'),
          rowsPerPage: t('filters.rowsPerPage'),
          displayRows: t('filters.displayRows'),
          jumpToPage: t('filters.jumpToPage')
        },
        toolbar: {
          search: t('filters.search'),
          viewColumns: t('filters.viewColumns'),
          filterTable: t('filters.filterTable')
        },
        body: {
          noMatch: t('filters.noMatch'),
          toolTip: t('filters.sort_of'),
          columnHeaderTooltip: (column) => t('filters.sort_of') + column.label
        },
        viewColumns: {
          title: t('filters.viewColumns')
        }
      },
      onFilterChange: (columnChanged, filterList) => this.filterData(filterList)
    }
    return (
      <div>
        <span title={t('common.exportPDF')}>
          <button
            onClick={this.generatePDF}
            className='btn buttonPrimaryColor float-right m-3'
          >
            <PictureAsPdfIcon />
          </button>
        </span>
        <ExcelFile
          element={
            <buttton className='btn btn-primary buttonPrimaryColor float-right m-3'>
              {t('common.download_excel')}{' '}
            </buttton>
          }
          filename={
            t('report.report') +
            ' ' +
            moment(this.state.startDate).format('DD/MM/YYYY') +
            ' ' +
            moment(this.state.endDate).format('DD/MM/YYYY')
          }
        >
          <ExcelSheet data={filteredReport} name={t('report.report')}>
            <ExcelColumn
              label={t('modules.attendance.employee')}
              value='person'
            />
            <ExcelColumn label={t('report.theoric_hour')} value='teoric_hour' />
            <ExcelColumn label={t('report.real_hour')} value='real_hour' />
            <ExcelColumn label={t('report.difference')} value='difference' />
          </ExcelSheet>
        </ExcelFile>
        <ButtonFilters></ButtonFilters>
        {timetables.length === 0 ? (
          <div className='spinner'>
            <h3 className='center primaryColor'>{t('common.loading')}</h3>
          </div>
        ) : (
          <div>
            {this.datePicker()}
            <MUIDataTable
              title={t('report.report')}
              columns={columns}
              data={timetables}
              onPageChange={() => {}}
              options={options}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(AttendanceReport)
