import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    buttonSave: PropTypes.bool,
    onSaveModal: PropTypes.func,
    children: PropTypes.node,
    onCloseModal: PropTypes.func.isRequired
  }

  saveModal = () => {
    const { onSaveModal } = this.props

    onSaveModal()
  }

  closeModal = () => {
    const { onCloseModal } = this.props

    onCloseModal()
  }

  render() {
    const { t, visible, children, title } = this.props
    const showHideClassName = visible ? 'modal modal-block' : 'modal hide'

    return (
      <div className={showHideClassName}>
        <div className='modal-main'>
          <div className='modal-header'>
            <h3>{title}</h3>
          </div>
          <div className='modal-content'>{children}</div>
          <div className='modal-footer d-flex justify-content-between'>
            <button className='btn buttonClose' onClick={this.closeModal}>
              {t('common.close')}
            </button>
            {this.props.buttonSave ? (
              <button
                className='btn buttonPrimaryColor'
                onClick={this.saveModal}
              >
                {t('common.save')}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Modal)
