import { Component } from 'react'
import moment from 'moment'
import AttendanceItem from './AttendanceItem'

class AttendanceHelper extends Component {
  constructor(props) {
    super(props)
  }

  calculateTimes = (key, info, shifts, user) => {
    const shiftTimeIn = this.getShiftTime(info, user, true)
    const shiftTimeOut = this.getShiftTime(info, user, false)

    const item = new AttendanceItem({
      key: key,
      info: info,
      user: user,
      shifts: shifts,
      shiftTimeIn: shiftTimeIn,
      shiftTimeOut: shiftTimeOut,
      t: this.props.t
    })

    return item.row()
  }

  getShiftTime = (info, user, isIn) => {
    const { t, timetables } = this.props

    let shiftTimeResult = '?'
    let tIn = moment(info.datetimeIn)
    let tInDay = new Date(tIn).getDay()
    let shiftId = parseInt(info.shiftEventId)

    let tim = timetables.filter(
      (t) =>
        t.userId === user.key &&
        t.startDate === tIn.format('yyyy-MM-DD') &&
        (t.shiftTypeId === shiftId || t.shiftTypeId === -1)
    )
    if (tim.length === 0) {
      tim = timetables.filter((t) => {
        const sameWeekday = new Date(t.startDate).getDay() === tInDay
        return (
          t.userId === user.key &&
          sameWeekday &&
          t.priority === 99 &&
          (t.shiftTypeId === shiftId || t.shiftTypeId === -1)
        )
      })
      if (tim.length > 1) {
        let copyTim = [...tim]
        copyTim.sort((a, b) =>
          moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1
        )
        tim = [copyTim[0]]
      }
    }
    if (tim.length > 0) {
      const [first] = tim
      let currentTimetable
      if (tim.length > 1) {
        currentTimetable = tim.find(
          (t) => t.priority === Math.min(...tim.map((a) => a.priority))
        )
      } else {
        currentTimetable = first
      }

      let timeValue = isIn
        ? currentTimetable.startTime
        : currentTimetable.endTime
      switch (timeValue) {
        case 'Free':
        case 'hl':
        case 'pl':
        case 'npl':
        case 'sl':
          shiftTimeResult = t('modules.attendance.' + timeValue.toLowerCase())
        default:
          shiftTimeResult = timeValue
          break
      }
    }

    return shiftTimeResult
  }
}

export default AttendanceHelper
