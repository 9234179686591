export const ATTENDANCE_SECTION = 'Attendance'
export const TIMETABLES_SECTION = 'Timetables'
export const USERS_SECTION = 'users'
export const SHIFT_TYPE_SECTION = 'ShiftTypes'

export const LEAVES_SECTION = 'leaves'

export const USERS_FIELD_EMAIL = 'Email'
export const USERS_FIELD_USERNAME = 'Username'
export const USERS_FIELD_IS_MANAGER = 'isManager'
export const USERS_FIELD_IS_ADMIN = 'isAdmin'

export const FORMAT_DATE = 'DD/MM/YYYY'
export const FORMAT_TIME = 'HH:mm'
export const FORMAT_TIME_DIFF = 'H[h] m[m]'
export const FORMAT_BIG_TIME_DIFF = 'D[d] H[h] m[m]'

export const DATE_START_TO_CALCULATE = '2000-12-31'
