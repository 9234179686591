import React, { Component } from 'react'
import AuthService from '../services/auth.service'
import { withTranslation } from 'react-i18next'
import userService from '../services/user.service'
import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8'
import bigLogo from '../img/logo/bigLogo.png'
import swal from 'sweetalert'

class SignIn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: null,
      password: null,
      errorMsg: null
    }

    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')
    if (email && password) {
      this.authenticate(
        email,
        CryptoAES.decrypt(
          password,
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString(CryptoENC),
        true
      )
    }
  }

  authenticate = async (email, password, remember) => {
    const result = await AuthService.login(email, password)

    if (result.user) {
      if (remember) {
        localStorage.setItem('email', email)
        localStorage.setItem(
          'password',
          CryptoAES.encrypt(password, process.env.REACT_APP_ENCRYPTION_KEY)
        )
      }
      this.props.onChange()
    } else this.loginError()
  }

  handleChange = (event) => {
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({
      [name]: value,
      errorMsg: null
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { username, password, remember } = this.state
    const { t } = this.props

    const email = await userService.getEmail(username)

    if (email === 'modules.signIn.only_managers_can_login') {
      return swal({
        icon: 'error',
        text: t(email),
        buttons: false,
        timer: '2000'
      })
    }

    if (!email) {
      this.loginError()
      return
    }

    localStorage.setItem('username', username)
    this.authenticate(email, password, remember)
  }

  loginError = () => {
    const { t } = this.props
    this.setState({ errorMsg: t('modules.signIn.loginError') })
  }

  render() {
    const { t } = this.props
    const { errorMsg } = this.state

    return (
      <center>
        <img src={bigLogo} className='bigLogo' alt='logo' />
        <div className='login-box align-middle text-white'>
          <form className='login-form ' onSubmit={this.handleSubmit}>
            <h2 className='mb-3'>{t('modules.signIn.signIn')}</h2>

            <div className='form-group m-5'>
              <input
                type='text'
                name='username'
                className='form-control'
                placeholder={t('modules.signIn.placeholders.username')}
                onChange={this.handleChange}
              />
            </div>

            <div className='form-group m-5'>
              <input
                type='password'
                name='password'
                className='form-control'
                placeholder={t('modules.signIn.placeholders.password')}
                onChange={this.handleChange}
              />
            </div>

            <div className='form-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  name='remember'
                  className='custom-control-input'
                  id='rememberCheck'
                  onChange={this.handleChange}
                />
                <label className='custom-control-label' htmlFor='rememberCheck'>
                  {t('modules.signIn.rememberMe')}
                </label>
              </div>
            </div>

            <button
              type='submit'
              className='btn btn-primary btn-block buttonPrimaryColor'
            >
              {t('modules.signIn.submit')}
            </button>

            <div className='login-error'>
              {errorMsg ?? <span>{errorMsg}</span>}
            </div>
          </form>
        </div>
      </center>
    )
  }
}

export default withTranslation()(SignIn)
