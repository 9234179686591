import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import bigLogo from '../img/logo/bigLogoPDF.png'
import moment from 'moment'

class ExportFiles {
  generatePDF(headerArray, bodyArray, title, pageText) {
    const doc = new jsPDF('l', 'pt', 'a4')

    // Logo
    doc.addImage(bigLogo, 'PNG', 40, 0, 80, 80)
    // Title
    doc.setFontSize(18)
    doc.text(330, 40, String(title))
    // Table
    doc.autoTable({
      margin: { top: 80, bottom: 50 },
      theme: 'plain',
      headStyles: {
        fillColor: '#A20152',
        textColor: '#FFFFFF',
        lineWidth: 0.01,
        lineColor: 0,
        fillStyle: 'DF',
        halign: 'center',
        valign: 'middle',
        columnWidth: 'auto',
        overflow: 'linebreak',
        fontSize: '7'
      },
      head: [headerArray],
      bodyStyles: {
        lineWidth: 0.01,
        lineColor: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: '7',
        cellPadding: 1
      },
      body: bodyArray
    })

    // Pagination
    const pageCount = doc.internal.getNumberOfPages()

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      // Current date and page
      doc.setFontSize(8)
      doc.text(String(moment(Date.now()).format('DD-MM-yyyy HH:mm')), 100, 560)
      doc.text(
        String(pageText) + ' ' + String(i) + ' / ' + String(pageCount),
        680,
        560
      )
    }
    // Download pdf
    doc.save(title + '.pdf')
  }
}

export default new ExportFiles()
