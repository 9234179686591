import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'

ReactDOM.render(
  <Suspense fallback=''>
    <HashRouter basename={process.env.REACT_APP_BASE_URL}>
      <App />
    </HashRouter>
  </Suspense>,
  document.getElementById('root')
)

serviceWorker.unregister()
