import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  FORMAT_DATE,
  FORMAT_TIME,
  FORMAT_TIME_DIFF,
  FORMAT_BIG_TIME_DIFF,
  DATE_START_TO_CALCULATE
} from '../constants'

class AttendanceItem extends Component {
  static propTypes = {
    key: PropTypes.string,
    info: PropTypes.object,
    user: PropTypes.object,
    shifts: PropTypes.array,
    shiftTimeIn: PropTypes.string,
    shiftTimeOut: PropTypes.string,
    t: PropTypes.func
  }

  constructor(props) {
    const { shiftTimeIn, shiftTimeOut, t } = props
    super(props)

    this.state = {
      workable: this.checkWorkable(shiftTimeIn, shiftTimeOut, t)
    }
  }

  checkWorkable = (shiftTimeIn, shiftTimeOut, t) => {
    switch ((shiftTimeIn, shiftTimeOut)) {
      case '?':
      case 'Free':
      case t('modules.attendance.free'):
      case t('modules.attendance.hl'):
      case t('modules.attendance.pl'):
      case t('modules.attendance.npl'):
      case t('modules.attendance.sl'):
        return false
    }
    return true
  }

  diffSign = (positive) => {
    return positive ? '+' : '-'
  }

  timeInDiff = () => {
    const { info, shiftTimeIn } = this.props
    const { workable } = this.state

    if (!workable) {
      return { valid: false, diff: '--', diffMinutes: 0 }
    }

    const timeIn = moment.utc(info.datetimeIn)
    const valid = info.inAuth !== 'rejected'
    const date = moment.utc(info.datetimeIn).format('yyyy-MM-DD')
    const teorTimeIn = workable
      ? moment.utc(timeIn.format('yyyy-MM-DD') + ' ' + shiftTimeIn)
      : moment.utc(date)
    const after = timeIn.isAfter(teorTimeIn)
    const diff = after
      ? moment.utc(timeIn.diff(teorTimeIn))
      : moment.utc(teorTimeIn.diff(timeIn))
    const sign =
      diff.format(FORMAT_TIME) == '00:00' ? '' : this.diffSign(valid && !after)

    return {
      valid: valid,
      diff: valid
        ? sign + diff.format(FORMAT_TIME_DIFF)
        : moment.utc(0).format(FORMAT_TIME_DIFF),
      diffMinutes: valid
        ? diff.hours() * 60 + diff.minutes() * (sign == '-' ? -1 : 1)
        : 0
    }
  }

  timeOutDiff = () => {
    const { info, shiftTimeIn, shiftTimeOut } = this.props
    const { workable } = this.state

    if (!workable) return this.timeOutNonWorkable()

    const timeOut = moment.utc(info.datetimeOut)
    const valid = info.datetimeOut && info.outAuth !== 'rejected'
    const date = moment.utc(info.datetimeIn).format('yyyy-MM-DD')
    let teorTimeOut = workable
      ? moment.utc(date + ' ' + shiftTimeOut) // on same day as IN
      : moment.utc(date)
    if (shiftTimeIn > shiftTimeOut) {
      teorTimeOut = moment.utc(teorTimeOut).add(1, 'days')
    }
    const after = timeOut.isAfter(teorTimeOut)
    const diff = after ? timeOut.diff(teorTimeOut) : teorTimeOut.diff(timeOut)
    const diffMoment = moment.utc(diff)
    const diffHours = moment.duration(diff).asHours()
    const diffDays = Math.trunc(moment.duration(diff).asDays())
    const sign =
      diffMoment.format(FORMAT_TIME) == '00:00'
        ? ''
        : this.diffSign(valid && after)
    const visualDiffFormat =
      diffHours > 24 ? FORMAT_BIG_TIME_DIFF : FORMAT_TIME_DIFF

    return {
      valid: valid,
      timeOut:
        timeOut.format(FORMAT_TIME) +
        (diffDays > 0 ? ` (${timeOut.format(FORMAT_DATE)})` : ''),
      diff: valid
        ? sign + diffMoment.add(-1, 'days').format(visualDiffFormat)
        : moment.utc(0).format(FORMAT_TIME_DIFF),
      diffMinutes: valid
        ? diffDays * 60 * 24 +
          diffMoment.hours() * 60 +
          diffMoment.minutes() * (sign == '-' ? -1 : 1)
        : 0
    }
  }

  timeOutNonWorkable = () => {
    const { info } = this.props

    const timeOut = moment.utc(info.datetimeOut)
    const workedDiff = timeOut.diff(moment.utc(info.datetimeIn))
    const momentDiff = moment.utc(workedDiff)
    const workedHours = moment.duration(workedDiff).asHours()
    const workedDays = Math.trunc(moment.duration(workedDiff).asDays())
    const format = workedHours > 24 ? FORMAT_BIG_TIME_DIFF : FORMAT_TIME_DIFF

    return {
      valid: true,
      timeOut:
        timeOut.format(FORMAT_TIME) +
        (workedDays > 0 ? ` (${timeOut.format(FORMAT_DATE)})` : ''),
      diff: '+' + momentDiff.add(-1, 'days').format(format),
      diffMinutes:
        workedDays * 60 * 24 + momentDiff.hours() * 60 + momentDiff.minutes()
    }
  }

  totalDiff = (diffMinutesIn, diffMinutesOut) => {
    const totalMinutes = diffMinutesIn + diffMinutesOut
    const sign = totalMinutes == 0 ? '' : this.diffSign(totalMinutes > 0)
    const visualDiffFormat =
      totalMinutes > 60 * 24 ? FORMAT_BIG_TIME_DIFF : FORMAT_TIME_DIFF

    return (
      sign +
      moment(DATE_START_TO_CALCULATE)
        .startOf('day')
        .add(Math.abs(totalMinutes), 'minutes')
        .format(visualDiffFormat)
    )
  }

  row = () => {
    const { info, user, shifts, shiftTimeIn, shiftTimeOut, key, t } = this.props

    const timeIn = moment(info.datetimeIn)
    const shift = shifts.find((s) => s.key === info.shiftEventId)
    const timeInDiff = this.timeInDiff()
    const timeOutDiff = this.timeOutDiff()
    const totalDiff = this.totalDiff(
      timeInDiff.diffMinutes,
      timeOutDiff.diffMinutes
    )

    return {
      key: key,
      person: user.name,
      pending:
        (info.inRequiresAuth === 'true' && info.inAuth === '') ||
        (info.outRequiresAuth === 'true' &&
          (info.outAuth === '' || info.outAuth === undefined))
          ? t('common.yes')
          : t('common.no'),
      user: user.key,
      date: timeIn.format('DD/MM/YYYY'),
      dateTimeInEdit: moment(info.datetimeIn).format('DD/MM/YYYY'),
      dateTimeOutEdit: moment(info.datetimeOut).format('DD/MM/YYYY'),
      shift: shift?.label,
      inRequiresAuth: info.inRequiresAuth,
      outRequiresAuth: info.outRequiresAuth,
      inAuth: info.inAuth,
      outAuth: info.outAuth === undefined ? '' : info.outAuth,
      lateInMsg:
        info.lateInMsg != null
          ? t(`attendance_reasons.` + info.lateInMsg)
          : t('attendance_reasons.no_msg'),
      lateOutMsg:
        info.lateOutMsg != null
          ? t(`attendance_reasons.` + info.lateOutMsg)
          : t('attendance_reasons.no_msg'),
      inResult: timeInDiff.diff,
      outResult: timeOutDiff.diff,
      totalResult: totalDiff,
      isTotalNegative: totalDiff.includes('-'),
      lateInMsg:
        info.lateInMsg != ''
          ? t(`attendance_reasons.` + info.lateInMsg)
          : t('attendance_reasons.no_msg'),
      lateOutMsg:
        info.lateOutMsg != ''
          ? t(`attendance_reasons.` + info.lateOutMsg)
          : t('attendance_reasons.no_msg'),
      timeIn: timeIn.format(FORMAT_TIME),
      timeOut: timeOutDiff.valid ? timeOutDiff.timeOut : ' - ',
      shiftTimeIn: shiftTimeIn,
      shiftTimeOut: shiftTimeOut,
      totalOvertimeMinutes: timeInDiff.diffMinutes + timeOutDiff.diffMinutes,
      shiftToExcel: t(`modules.attendance.shifts.` + shift?.label)
    }
  }
}

export default AttendanceItem
