import { database } from '../firebase'
import { SHIFT_TYPE_SECTION } from '../constants'

const db = database.ref('/' + SHIFT_TYPE_SECTION)

class ShiftTypesService {
  getAll() {
    return db
  }
}

export default new ShiftTypesService()
