import { database } from '../firebase'
import { LEAVES_SECTION } from '../constants'

const db = database.ref('/' + LEAVES_SECTION)

class LeaveService {
  getAll() {
    return db
  }
  delete(user, key) {
    return db.child(user).child(key).remove()
  }

  update(user, key, values) {
    return db.child(user).child(key).update(values)
  }
}

export default new LeaveService()
