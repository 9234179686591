import { auth } from '../firebase'
import firebase from 'firebase'
class AuthService {
  login = async (username, password) => {
    let user = null
    let error = {}

    try {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const userCredential = await auth.signInWithEmailAndPassword(
        username,
        password
      )
      user = userCredential.user
    } catch (exception) {
      console.log(exception.message)
      error = exception
    }
    return {
      user: user,
      error: error
    }
  }

  signOut = async () => {
    this.clearCache()
    return await auth.signOut()
  }

  clearCache = () => {
    localStorage.setItem('email', null)
    localStorage.setItem('username', null)
    localStorage.setItem('password', null)
  }

  currentUser() {
    return auth.currentUser
  }
}

export default new AuthService()
