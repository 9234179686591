import React, { Component } from 'react'
import { RangeDatePicker } from 'react-google-flight-datepicker'

class DatePickerRange extends Component {
  constructor(props) {
    super(props)
  }

  render = () => {
    const { t, filter } = this.props

    return (
      <RangeDatePicker
        onChange={(start, end) => filter(start, end)}
        minDate={new Date(1900, 0, 1)}
        maxDate={new Date(2100, 0, 1)}
        dateFormat='DD/MM/YYYY'
        monthFormat='MMM YYYY'
        startDatePlaceholder={t('report.start_date')}
        endDatePlaceholder={t('report.end_date')}
        disabled={false}
        className='react-google-flight-datepicker'
        startWeekDay='monday'
      />
    )
  }
}

export default DatePickerRange
